import {
  Attendance,
  AttendanceRecord,
  Filter,
  HomeroomObject,
  StudentInterface,
} from "../interfaces/interfaces";

export const createTotalsRowObj = (daysInCalendarYear: string[]) => {
  const totalsObj: { [key: string]: number } = daysInCalendarYear.reduce((accumulator, value) => {
    return { ...accumulator, [value]: 0 };
  }, {});
  return totalsObj;
};

type filterBySearchTermProps = {
  filters: Filter;
  students: StudentInterface[];
};

export const filterBySearchTerm = ({ filters, students }: filterBySearchTermProps) => {
  const filteredStudentsByName = [];
  if (filters.searchTerm?.length > 0) {
    const filteredResults = students.filter(
      (student) =>
        String(student.childFirstName).toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
        String(student.childLastName).toLowerCase().includes(filters.searchTerm.toLowerCase())
    );
    filteredStudentsByName.push(...filteredResults);
  } else {
    filteredStudentsByName.push(...students);
  }
  return filteredStudentsByName;
};

type filterByDateProps = {
  filters: Filter;
  records: AttendanceRecord[];
};

export const filterByDate = ({ filters, records }: filterByDateProps) => {
  const [startDate, endDate] = filters.dateRange;
  if (startDate && endDate) {
    const filteredByDate = records.filter((record) => {
      const recordDate = new Date(record.absenceDate);
      return recordDate >= new Date(startDate) && recordDate <= new Date(endDate);
    });
    return filteredByDate;
  } else {
    return records;
  }
};

type filterByHomeroomProps = {
  students: StudentInterface[];
  filters: Filter;
  selectedHomeroom: string;
};

export const filterByHomeroom = ({
  students,
  filters,
  selectedHomeroom,
}: filterByHomeroomProps) => {
  if (selectedHomeroom === "all") {
    return students;
  } else {
    return students.filter((student) => student.homeroom === selectedHomeroom);
  }
};

type filterByStudentProps = {
  records: AttendanceRecord[];
  student: StudentInterface;
};

export const filterByStudent = ({ records, student }: filterByStudentProps) => {
  return records.filter((record) => record.SID === student.SID);
};

type filterByCodeProps = {
  filters: Filter;
  records: AttendanceRecord[];
};

export const filterByCode = ({ records, filters }: filterByCodeProps) => {
  return records.filter((record) => filters.absenceTypes.includes(record.absenceCode));
};

export const buildFirstRow = (totalsRowObj: { [key: string]: number }) => {
  return {
    firstName: "TOTALS",
    lastName: "",
    homeroom: "",
    id: "",
    count: "",
    SID: "",
    ...totalsRowObj,
  };
};

type buildRowProps = {
  student: StudentInterface;
  homeroomsObj: HomeroomObject;
  filteredByCode: AttendanceRecord[];
  groupedAttendanceRecords: { [key: string]: AttendanceRecord[] };
};

export const buildRow = ({
  student,
  homeroomsObj,
  filteredByCode,
  groupedAttendanceRecords,
}: buildRowProps) => {
  return {
    firstName: student.childFirstName,
    lastName: student.childLastName,
    SID: student.SID,
    homeroom: homeroomsObj[student.homeroom]?.name || "",
    id: student.id,
    count: filteredByCode.length,
    ...groupedAttendanceRecords,
  };
};
