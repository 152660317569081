import { Box, TextField } from "@mui/material";
import { filtersAtom } from "../../recoil/atoms";
import { useRecoilState } from "recoil";

function SearchBar() {
  const [filters, setFilters] = useRecoilState(filtersAtom);
  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((pV) => ({ ...pV, searchTerm: event.target.value }));
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, padding: 2 }}>
      <Box>
        <TextField
          placeholder={"Student Search"}
          value={filters.searchTerm}
          onChange={handleSearchTermChange}
          fullWidth
        />
      </Box>
    </Box>
  );
}

export default SearchBar;
