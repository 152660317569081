import {
  attendanceRecordsAtom,
  daysInCalendarYearAtom,
  filtersAtom,
  homeroomsAtom,
  homeroomsObjAtom,
  selectedHomeroomAtom,
  studentsAtom,
} from "./atoms";
import { GetRecoilValue } from "recoil";
import { AttendanceTableRow, HomeroomObject } from "../interfaces/interfaces";
import {
  createTotalsRowObj,
  filterBySearchTerm,
  filterByDate,
  filterByStudent,
  filterByCode,
  buildFirstRow,
  buildRow,
  filterByHomeroom,
} from "./functions";
import lodash from "lodash";

type Props = {
  get: GetRecoilValue;
};

export const attendanceTableRowsGetter = ({ get }: Props) => {
  const startingTime = Date.now();
  const attendanceRecords = get(attendanceRecordsAtom);
  const students = get(studentsAtom);
  const daysInCalendarYear = get(daysInCalendarYearAtom);
  const homeroomsObj = get(homeroomsObjAtom);
  const filters = get(filtersAtom);
  const selectedHomeroom = get(selectedHomeroomAtom);
  if (
    attendanceRecords.length === 0 ||
    students.length === 0 ||
    !homeroomsObj ||
    Object.keys(homeroomsObj).length === 0 ||
    !daysInCalendarYear ||
    !filters
  )
    return null;
  console.log("After Bumper: ", Date.now() - startingTime);

  const tempArray: AttendanceTableRow[] = [];
  const totalsRowObj = createTotalsRowObj(daysInCalendarYear);
  const filteredByHomeroom = filterByHomeroom({ selectedHomeroom, students, filters });
  const filteredStudentsByName = filterBySearchTerm({ filters, students: filteredByHomeroom });

  console.log("Beginning Student Loop: ", Date.now() - startingTime);
  filteredStudentsByName.forEach((student) => {
    const filteredByStudent = filterByStudent({ records: attendanceRecords, student });
    const filteredByDate = filterByDate({ records: filteredByStudent, filters });
    const filteredByCode = filterByCode({ records: filteredByDate, filters });
    const groupedAttendanceRecords = lodash.groupBy(filteredByCode, "absenceDate");

    tempArray.push(buildRow({ filteredByCode, student, groupedAttendanceRecords, homeroomsObj }));

    daysInCalendarYear.forEach((day) => {
      totalsRowObj[day] =
        totalsRowObj[day] +
        (groupedAttendanceRecords[day] ? groupedAttendanceRecords[day].length : 0);
    });
  });
  console.log("After Student Loop: ", Date.now() - startingTime);
  const firstRow = buildFirstRow(totalsRowObj);

  return { firstRow: firstRow, studentRows: tempArray };
};

export const buildHomeroomObjectGetter = ({ get }: Props) => {
  const homerooms = get(homeroomsAtom);
  const tempObj: HomeroomObject = {};
  homerooms.forEach((homeroom) => {
    tempObj[homeroom.id] = { ...homeroom };
  });
  return tempObj;
};
