import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "./SearchBar";
import FilterDateRange from "./FilterDateRange";
import FilterAbsenceTypes from "./FilterAbsenceTypes";

function FiltersAccordion() {
  return (
    <>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ height: "40px !important", margin: "0 !important", minHeight: "0px !important" }}
          >
            <Typography variant="h6">Filters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pl: 2, pr: 2, pt: 0 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <SearchBar />
              <FilterDateRange />
              <Box sx={{flexBasis: "100%"}}></Box>
              <FilterAbsenceTypes />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default FiltersAccordion;
