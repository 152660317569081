import { Filter } from "../interfaces/interfaces";

export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_SETTINGS = "Edit Settings",
  SEE_ALL_ROASTER = "See All Roasters",
}

export const PERMISSIONS: Array<PERMISSION> = [
  PERMISSION.EDIT_STAFF,
  PERMISSION.EDIT_SETTINGS,
  PERMISSION.SEE_ALL_ROASTER,
];

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
}

export enum COLLECTION {
  daysPerWeek = "daysPerWeek",
  incidentTypes = "incidentTypes",
  reports = "reports",
  staff = "staff",
}
export const COLLECTIONS = Object.values(COLLECTION);

export const ABSENCE_TYPES = {
  A: "Excused",
  U: "Unexcused",
  S: "Suspended",
  M: "Medically Excused",
};

export const ABSENCE_TYPES_ARRAY: { key: string; value: string }[] = [
  { key: "A", value: "Excused" },
  { key: "U", value: "Unexcused" },
  { key: "S", value: "Suspended" },
  { key: "M", value: "Medically Excused" },
];

export const ABSENCE_TYPES_KEYS_ARRAY = Object.keys(ABSENCE_TYPES);

export const BLANK_FILTERS: Filter = {
  dateRange: [null, null],
  searchTerm: "",
  absenceTypes: ABSENCE_TYPES_KEYS_ARRAY,
};

export const SCHOOL_YEARS = {
  SY21_22: "SY 21 - 22",
  SY22_23: "SY 22 - 23",
  SY23_24: "SY 23 - 24",
  SY24_25: "SY 24 - 25",
};

export const SCHOOL_DAYS = [
  "08/15/2022",
  "08/16/2022",
  "08/17/2022",
  "08/18/2022",
  "08/19/2022",
  "08/22/2022",
  "08/23/2022",
  "08/24/2022",
  "08/25/2022",
  "08/26/2022",
  "08/29/2022",
  "08/30/2022",
  "08/31/2022",
];
