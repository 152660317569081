import React from "react";
import FiltersAccordion from "../Filter/FiltersAccordion";

function AttendanceTrackingContainer() {
  return (
    <>
      <FiltersAccordion />
    </>
  );
}

export default AttendanceTrackingContainer;
