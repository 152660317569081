import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import HomeroomSelect from "./HomeroomSelect";

function Weblinks() {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  return (
    <>
      {/* can set permissions here */}
      {loggedInStaff && (
        <>
          <Link className="webLink" to="/attendance-upload">
            Upload
          </Link>
          <Link className="webLink" to="/attendance-table">
            Table
          </Link>
          <HomeroomSelect />
        </>
      )}
    </>
  );
}

export default Weblinks;
