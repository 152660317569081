import { TextField, Box } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { filtersAtom } from "../../recoil/atoms";
import { useRecoilState } from "recoil";

export default function FilterDateRange() {
  const [filters, setFilters] = useRecoilState(filtersAtom);
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DateRangePicker
        startText="Reports on or After"
        endText="Reports Before"
        value={filters.dateRange}
        onChange={(dateRange: any) => {
          setFilters({ ...filters, dateRange });
        }}
        renderInput={(startProps: any, endProps: any) => (
          <>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}
