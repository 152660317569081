import { atom, selector } from "recoil";
import {
  StaffInterface,
  StaffFromAPIInterface,
  HomeroomInterface,
  StudentInterface,
  AttendanceRecord,
  AttendanceTableRow,
  HomeroomObject,
  Filter,
} from "../interfaces/interfaces";
import { BLANK_FILTERS } from "../libraries/objects";

import { attendanceTableRowsGetter, buildHomeroomObjectGetter } from "./selectors";

export const staffAtom = atom<StaffInterface[]>({
  key: "staff",
  default: [],
});

export const loggedInStaffAtom = atom<StaffInterface | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const staffFromAPIAtom = atom<StaffFromAPIInterface[]>({
  key: "staffFromAPI",
  default: [],
});

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<HomeroomInterface[]>({
  key: "homerooms",
  default: [],
});

export const homeroomsObjAtom = selector<HomeroomObject | null>({
  key: "homeroomsObj",
  get: buildHomeroomObjectGetter,
});

export const selectedHomeroomAtom = atom<string>({
  key: "selectedHomeroom",
  default: "all",
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<StudentInterface[]>({
  key: "students",
  default: [],
});

export const selectedStudentIdAtom = atom<string | null>({
  key: "selectedStudentId",
  default: null,
});

export const attendanceRecordsAtom = atom<AttendanceRecord[]>({
  key: "attendanceRecords",
  default: [],
});

export const attendanceTableRowsAtom = selector<{
  studentRows: AttendanceTableRow[];
  firstRow: AttendanceTableRow;
} | null>({
  key: "attendanceTableRows",
  get: attendanceTableRowsGetter,
});

export const daysInCalendarYearAtom = atom<string[]>({
  key: "daysInCalendarYear",
  default: [],
});

export const filtersAtom = atom<Filter>({
  key: "filters",
  default: BLANK_FILTERS,
});
