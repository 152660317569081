import { useState, SyntheticEvent } from "react";
import Papa from "papaparse";
import { useRecoilValue } from "recoil";
import { attendanceRecordsAtom } from "../../recoil/atoms";
import useAddDoc from "../../hooks/useAddDoc";
import AttendanceUploadForm from "./AttendanceUploadForm";
import { Attendance } from "../../interfaces/interfaces";

type FormState = EventTarget & {
  name: string;
  value: string;
  files: File[];
};

const AttendanceUploadContainer = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const attendanceRecords = useRecoilValue(attendanceRecordsAtom);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { sendRequest: addDoc } = useAddDoc();
  const [last, setLast] = useState(false);

  //type Column = "Student Id" | "Grade";
  enum Column {
    STUDENT_ID = "Student ID",
    GRADE = "Grade",
    STUDENT_NAME = "Student Name",
    ABSENCE_DATE = "Absence Date",
    ABSENCE_PERIOD = "Absence Period",
    POSTING_CODE = "Posting Code",
  }
  type AttendanceDictonary = {
    ["Student ID"]: string;
    ["Grade"]: string;
    ["Student Name"]: string;
    ["Absence Date"]: string;
    ["Absence Period"]: string;
    ["Posting Code"]: "A" | "M" | "U" | "S";
  };

  const searchRecords = (newRecord: Attendance) => {
    if (!attendanceRecords) return;
    const matchingRecords = attendanceRecords.filter(
      (existingRecord) =>
        existingRecord.SID === newRecord.SID && existingRecord.absenceDate === newRecord.absenceDate
    );
    return matchingRecords.length > 0 ? true : false;
  };
  const fromWebsite = async (value: AttendanceDictonary) => {
    if (value[Column.ABSENCE_PERIOD] !== "02") return;
    const ABSENCE_CODES: ["A", "U", "M", "S"] = ["A", "U", "M", "S"];
    if (!ABSENCE_CODES.includes(value[Column.POSTING_CODE])) return;
    const attendanceLog: Attendance = {
      SID: value[Column.STUDENT_ID] || "",
      grade: value[Column.GRADE] || "",
      studentName: value[Column.STUDENT_NAME] || "",
      absenceDate: value[Column.ABSENCE_DATE] || "",
      absencePeriod: value[Column.ABSENCE_PERIOD] || "",
      absenceCode: value[Column.POSTING_CODE] || "U",
    };
    return searchRecords(attendanceLog)
      ? undefined
      : addDoc({ col: "attendanceLogs", data: attendanceLog });
  };

  const changeHandler = (event: SyntheticEvent) => {
    const formState = event.target as FormState;
    setSelectedFile(formState.files[0]);
  };

  const handleSubmission = async () => {
    if (selectedFile && selectedFile.type === "text/csv") {
      const promises: Array<Promise<string | null | undefined>> = [];
      setLoading(true);
      Papa.parse<AttendanceDictonary>(selectedFile, {
        complete: async (results) => {
          const length = results.data.length;
          results.data.forEach((value, index) => {
            if (length === index + 1) {
              setLast(true);
            }
            const resultId = fromWebsite(value);
            promises.push(resultId);
          });
          console.log("Promises references", promises.length);
          await Promise.all(promises);
          setLoading(false);
        },
        header: true,
      });
    }
  };

  return (
    <AttendanceUploadForm
      changeHandler={changeHandler}
      handleSubmission={handleSubmission}
      successMessage={successMessage}
      loading={loading}
      selectedFile={selectedFile}
    />
  );
};

export default AttendanceUploadContainer;
