import { useRecoilState, useRecoilValue } from "recoil";
import { selectedHomeroomAtom, homeroomsAtom } from "../../recoil/atoms";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

export default function HomeroomSelect() {
  const [selectedHomeroom, setSelectedHomeroom] = useRecoilState(selectedHomeroomAtom);
  const homerooms = useRecoilValue(homeroomsAtom);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedHomeroom(event.target.value);
  };

  return (
    <>
      {selectedHomeroom && (
        <Select
          id="select-homeroom"
          sx={{ color: "white", fontWeight: 800, borderColor: "#fff", maxWidth: "30vw" }}
          value={selectedHomeroom}
          label="Homeroom"
          onChange={handleChange}
        >
          <MenuItem value="all">All</MenuItem>
          {homerooms &&
            homerooms.map((homeroom) => (
              <MenuItem key={homeroom?.id ?? ""} value={homeroom.id}>
                {homeroom.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </>
  );
}
