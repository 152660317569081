import { useEffect, useContext } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  staffAtom,
  loggedInStaffAtom,
  attendanceRecordsAtom,
  daysInCalendarYearAtom,
} from "../recoil/atoms";
import { AttendanceRecord, StaffInterface } from "../interfaces/interfaces";
import { AuthContext } from "../providers/AuthProvider";
import { parseAttendanceResponse, parseStaffResponse } from "../libraries/parsers";
import useDaysInCalendarYear from "./useDaysInCalendarYear";

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { currentAuthUser } = useContext(AuthContext);
  const staff = useRecoilValue(staffAtom);
  const setStaff = useSetRecoilState<StaffInterface[]>(staffAtom);
  const setAttendanceRecords = useSetRecoilState<AttendanceRecord[]>(attendanceRecordsAtom);
  const setLoggedInStaff = useSetRecoilState<StaffInterface | null>(loggedInStaffAtom);
  const setDaysInCalendarYear = useSetRecoilState(daysInCalendarYearAtom);
  const { generateDaysInCalendarYear } = useDaysInCalendarYear();

  useEffect(() => {
    setDaysInCalendarYear(generateDaysInCalendarYear({ startingYear: 2022 }));
  }, [setDaysInCalendarYear, generateDaysInCalendarYear]);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<StaffInterface>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs]);

  useEffect(() => {
    const getAttendance = async () => {
      const response = await getDocs<AttendanceRecord>({ col: "attendanceLogs",  });
      if (response) {
        setAttendanceRecords(parseAttendanceResponse(response));
      }
    };
    getAttendance();
  }, [setAttendanceRecords, getDocs]);

  useEffect(() => {
    if (!currentAuthUser || staff.length === 0) return;
    const filteredStaff = staff.filter(
      (staffMember) => staffMember.email === currentAuthUser.email
    );
    if (filteredStaff.length === 1) {
      setLoggedInStaff(filteredStaff[0]);
    } else {
      //generate message
      console.error(
        "[Hook] useBootstrapEffect - filtered Staff, no DB user found or multiple DB users found",
        currentAuthUser.email,
        filteredStaff.length
      );
    }
  }, [currentAuthUser, staff, setLoggedInStaff]);
};

export default useBootstrapEffect;
