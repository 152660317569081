import { Container } from "@mui/material";
import AttendanceUploadContainer from "../components/AttendanceUpload/AttendanceUploadContainer";
import Navbar from "../components/Navigation/Navbar";

const AttendanceUploadPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <AttendanceUploadContainer />
      </Container>
    </>
  );
};

export default AttendanceUploadPage;