import { useCallback } from "react";
import { ColumnInterface } from "../interfaces/interfaces";
import { GridValueGetterParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";

interface SubmitFormProps {
  startingYear: number;
}

type SubmitFormType = (props: SubmitFormProps) => ColumnInterface[];

type UseSubmitFormType = {
  generateColumns: SubmitFormType;
};

const getCellFormat = (params: GridValueFormatterParams) => {
  if (typeof params.value === "number") {
    return params.value;
  } else {
    return params.value && params.value.length === 1 ? params.value[0].absenceCode : "";
  }
  //return value;
};

const usePerDayColumns = (): UseSubmitFormType => {
  const generateColumns = useCallback<SubmitFormType>(({ startingYear }) => {
    const tempColumns: ColumnInterface[] = [];
    for (let i = 365; i >=0; i--) {
      const currentDate = new Date(2022, 6, i);
      const columnName = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const headerName = currentDate.toLocaleDateString();
      const field: ColumnInterface = {
        field: columnName,
        headerName: headerName,
        minWidth: 120,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
        valueFormatter: getCellFormat,
      };
      tempColumns.push(field);
    }
    return tempColumns;
  }, []);
  return { generateColumns };
};

export default usePerDayColumns;
