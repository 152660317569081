import { Typography } from "@mui/material";
import React from "react";
import { AttendanceRecord } from "../../interfaces/interfaces";
import { ABSENCE_TYPES } from "../../libraries/objects";

type Props = {
  studentRecords: AttendanceRecord[];
};

function AttendanceRecordsList({ studentRecords }: Props) {
  return (
    <>
      {studentRecords.map((record) => (
        <Typography key={record.id}>{`${record.absenceDate} - ${
          ABSENCE_TYPES[record.absenceCode]
        }`}</Typography>
      ))}
    </>
  );
}

export default AttendanceRecordsList;
