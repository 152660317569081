import { useEffect, useState } from "react";
import { GridToolbar, DataGridPro, GridPinnedRowsProp, GridRowParams } from "@mui/x-data-grid-pro";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { attendanceRecordsAtom, attendanceTableRowsAtom } from "../../recoil/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { attendanceTableColumns } from "../../libraries/columns";
import { selectedStudentIdAtom } from "../../recoil/atoms";
import usePerDayColumns from "../../hooks/usePerDayColumns";
import { AttendanceTableRow, ColumnInterface } from "../../interfaces/interfaces";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function AttendanceTable({ open, setOpen }: Props) {
  const [studentRows, setStudentRows] = useState<AttendanceTableRow[] | null>(null);
  const [firstRow, setFirstRow] = useState<GridPinnedRowsProp | null>(null);
  const [columns, setColumns] = useState<ColumnInterface[] | null>(null);
  const attendanceRecords = useRecoilValue(attendanceRecordsAtom);
  const attendanceTableRows = useRecoilValue(attendanceTableRowsAtom);
  const setSelectedStudentId = useSetRecoilState(selectedStudentIdAtom);
  const { generateColumns } = usePerDayColumns();
  const window = useWindowDimensions();

  useEffect(() => {
    if (!attendanceRecords || !attendanceTableRows) return;
    setStudentRows(attendanceTableRows.studentRows);

    const dateColumns = generateColumns({ startingYear: 2022 });
    const tempArray: ColumnInterface[] = [...attendanceTableColumns];
    dateColumns.forEach((column) => {
      if (attendanceTableRows.firstRow[column.field] === 0) return;
      tempArray.push(column);
    });
    setColumns(tempArray);
    const pinnedRows: GridPinnedRowsProp = {
      top: [attendanceTableRows.firstRow],
    };
    setFirstRow(pinnedRows);
  }, [attendanceRecords, generateColumns, attendanceTableRows]);

  const handleClick = (params: GridRowParams) => {
    setSelectedStudentId(params.row.id);
    setOpen(true);
  };

  return (
    <div>
      {studentRows && firstRow && attendanceRecords && columns ? (
        <div style={{ height: window.height - 300 }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGridPro
                rows={studentRows}
                columns={columns}
                rowHeight={34}
                columnBuffer={2}
                pinnedRows={firstRow}
                experimentalFeatures={{ rowPinning: true }}
                onRowClick={handleClick}
                components={{
                  Toolbar: GridToolbar,
                }}
                initialState={{
                  pinnedColumns: { left: ["firstName", "lastName"] },
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={80} />
        </Box>
      )}
    </div>
  );
}
