import { BrowserRouter as Router } from "react-router-dom";
import useBootstrapEffect from "./hooks/useBootstrapEffect";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import { routes } from "./Routes";
import useBootstrapAPIEffect from "./hooks/useBootstrapAPIEffect";
import { LicenseInfo } from "@mui/x-license-pro";
const KEY = process.env.REACT_APP_DATATABLE_KEY ?? "";
LicenseInfo.setLicenseKey(KEY);

function AppBootstrap() {
  useBootstrapEffect();
  useBootstrapAPIEffect();
  return (
    
      <ThemeProvider theme={theme}>
        <Router>
          <main className="root">{routes}</main>
        </Router>
      </ThemeProvider>
  );
}

export default AppBootstrap;
