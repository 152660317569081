import { Container } from "@mui/material";
import AttendanceTableContainer from "../components/AttendanceTable/AttendanceTableContainer";
import Navbar from "../components/Navigation/Navbar";

const AttendanceTablePage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <AttendanceTableContainer />
      </Container>
    </>
  );
};

export default AttendanceTablePage;
