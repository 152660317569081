import React from "react";
import { ABSENCE_TYPES_ARRAY } from "../../libraries/objects";
import { FormControlLabel, Checkbox } from "@mui/material";
import { filtersAtom } from "../../recoil/atoms";
import { useRecoilState } from "recoil";

function FilterAbsenceTypes() {
  const [filters, setFilters] = useRecoilState(filtersAtom);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedArray = [...filters.absenceTypes];
    if (event.target.checked) {
      selectedArray.push(event.target.name);
    } else {
      const index = selectedArray.indexOf(event.target.name);
      if (index > -1) {
        selectedArray.splice(index, 1);
      }
    }
    setFilters((pV) => ({ ...pV, absenceTypes: selectedArray }));
  };
  return (
    <>
      {ABSENCE_TYPES_ARRAY.map((absenceType) => (
        <FormControlLabel
          key={absenceType.key}
          control={
            <Checkbox
              onChange={handleChange}
              name={absenceType.key}
              checked={filters.absenceTypes.includes(absenceType.key)}
            />
          }
          label={absenceType.value}
        />
      ))}
    </>
  );
}

export default FilterAbsenceTypes;
