import { useCallback } from "react";

interface SubmitFormProps {
  startingYear: number;
}

type SubmitFormType = (props: SubmitFormProps) => string[];

type UseSubmitFormType = {
  generateDaysInCalendarYear: SubmitFormType;
};

const useDaysInCalendarYear = (): UseSubmitFormType => {
  const generateDaysInCalendarYear = useCallback<SubmitFormType>(({ startingYear }) => {
    const tempArray: string[] = [];
    for (let i = 0; i < 366; i++) {
      const currentDate = new Date(startingYear, 6, i);
      tempArray.push(
        currentDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    }
    return tempArray;
  }, []);
  return { generateDaysInCalendarYear };
};

export default useDaysInCalendarYear;
