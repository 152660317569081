import { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import {
  selectedStudentIdAtom,
  studentsAtom,
  attendanceRecordsAtom,
  homeroomsObjAtom,
} from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { AttendanceRecord, StudentInterface } from "../../interfaces/interfaces";
import AttendanceRecordsList from "./AttendanceRecordsList";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

type Falsy = null | undefined | false;

export default function StudentDialogContainer({ open, setOpen }: Props) {
  const selectedStudentId = useRecoilValue(selectedStudentIdAtom);
  const students = useRecoilValue(studentsAtom);
  const attendanceRecords = useRecoilValue(attendanceRecordsAtom);
  const homeroomsObj = useRecoilValue(homeroomsObjAtom);
  const [student, setStudent] = useState<StudentInterface | Falsy>(null);
  const [studentRecords, setStudentRecords] = useState<AttendanceRecord[] | Falsy>(null);

  useEffect(() => {
    if (!selectedStudentId || !students || !attendanceRecords) return;
    const selectedStudent = students.find((student) => student.id === selectedStudentId);
    setStudent(selectedStudent);
    const tempRecords = attendanceRecords.filter((record) => record.SID === selectedStudent?.SID);
    tempRecords.sort((a: AttendanceRecord, b: AttendanceRecord) => {
      if (!a.absenceDate || !b.absenceDate) return 0;
      const bDate = new Date(b.absenceDate);
      const aDate = new Date(a.absenceDate);
      return bDate.getTime() - aDate.getTime();
    });
    setStudentRecords(tempRecords);
  }, [selectedStudentId, students, attendanceRecords]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {student && homeroomsObj && studentRecords && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent>
            <Typography sx={{ mb: 2 }} variant="h5">
              Student Details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Child's Name:{" "}
              </Typography>
              <Typography component="span">{`${student.childFirstName} ${student.childLastName}`}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography component="span" sx={{ fontWeight: 700 }}>
                Homeroom:{" "}
              </Typography>
              <Typography component="span">{`${homeroomsObj[student.homeroom].name}`}</Typography>
            </Box>
            <Box sx={{ mt: 2, maxHeight: "400px", overflow: "scroll" }}>
              <AttendanceRecordsList studentRecords={studentRecords} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
