import { useState } from "react";
import AttendanceTable from "./AttendanceTable";
import { Typography, Container } from "@mui/material";
import FiltersAccordion from "../Filter/FiltersAccordion";
import StudentDialogContainer from "../StudentDialog/StudentDialogContainer";

export default function AttendanceTableContainer() {
  const [open, setOpen] = useState(false);
  return (
    <Container sx={{ backgroundColor: "#fff", pt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Attendance Per Day
      </Typography>
      <FiltersAccordion />
      <AttendanceTable open={open} setOpen={setOpen} />
      <StudentDialogContainer open={open} setOpen={setOpen} />
    </Container>
  );
}
