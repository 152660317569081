import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginContainer from "./components/Login/LoginContainer";
import StaffPage from "./pages/StaffPage";
import CustomRoute from "./CustomRoute";
import { PERMISSION } from "./libraries/objects";
import AttendanceUploadPage from "./pages/AttendanceUploadPage";
import AttendanceTablePage from "./pages/AttendanceTablePage";
import AttendanceTrackingPage from "./pages/AttendanceTrackingPage";

export const routes = (
  <Routes>
    <Route path="/login" element={<LoginContainer />} />
    <Route
      path="/staff"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <StaffPage />
        </CustomRoute>
      }
    />
    <Route
      path="/attendance-upload"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <AttendanceUploadPage />
        </CustomRoute>
      }
    />
    <Route
      path="/attendance-table"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <AttendanceTablePage />
        </CustomRoute>
      }
    />
    <Route
      path="/attendance-tracking"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <AttendanceTrackingPage />
        </CustomRoute>
      }
    />
    <Route
      path="/"
      element={
        <CustomRoute permission={PERMISSION.EDIT_STAFF}>
          <AttendanceTablePage />
        </CustomRoute>
      }
    />
  </Routes>
);
